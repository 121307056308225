import React from 'react';
import { OutsideLink } from '@talves/gatsby-theme-components-tui';
import { Box, useThemeUI } from '../../../provider';
import PostListItem from '../list-item';
import BlogPostCard from './blog-post-card';
import { rankings as matchSorterRankings } from 'match-sorter';
import MatchSorterWorker from './match-sorter.worker';

// Original: Kent C. Dodds ©
// https://github.com/kentcdodds/kentcdodds.com/blob/master/src/components/search/index.js

// Lazy initialization
let matchSorterWorker;

function getMatchSorterWorker() {
  if (!matchSorterWorker) {
    matchSorterWorker = new MatchSorterWorker();
    // console.log('matchSorterWorker', matchSorterWorker);
  }
  return matchSorterWorker;
}
// End Lazy initialization

const wordExists = (str, searchString) => {
  const strArray = searchString.split(' ');
  for (var j = 0; j < strArray.length; j++) {
    if (strArray[j] === str) return true;
  }
  return false;
};

const MemoizedBlogPostCard = React.memo(BlogPostCard);
const BlogPostCards = ({ blogposts }) => (
  <Box
    sx={{
      marginTop: 20,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    }}
  >
    {blogposts.map(blogpost => (
      <MemoizedBlogPostCard key={blogpost.id} blogpost={blogpost} />
    ))}
  </Box>
);

const MemoizedPostListItem = React.memo(PostListItem);
const BlogPostList = ({ blogposts }) => (
  <Box
    sx={{
      marginTop: 20,
    }}
  >
    {blogposts.map(blogpost => (
      <MemoizedPostListItem key={blogpost.id} data={blogpost} />
    ))}
  </Box>
);

function useQueryParamState(searchParamName) {
  const [value, setValue] = React.useState(() => {
    if (typeof window === 'undefined') {
      return '';
    }
    const searchParams = new URL(window.location).searchParams;
    if (searchParams.has(searchParamName)) {
      return searchParams.get(searchParamName);
    } else {
      return '';
    }
  });

  React.useEffect(() => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.set(searchParamName, value);
    if (value) {
      window.history.replaceState(window.history.state, '', newUrl);
    } else {
      newUrl.searchParams.delete(searchParamName);
      window.history.replaceState(window.history.state, '', newUrl);
    }
  }, [searchParamName, value]);

  return [value, setValue];
}

const CategoryButton = ({ children, isSelected, ...props }) => {
  const { theme } = useThemeUI();
  const baseStyles = {
    cursor: 'pointer',
    padding: '2px 4px',
    border: `1px solid ${theme.colors.primary}`,
    backgroundColor: theme.colors.background,
    borderRadius: 3,
    fontSize: ['0.8em', '0.9em', '1em'],
    margin: '2.5px',
  };
  const hoverStyles = isSelected
    ? {
        color: theme.colors.background,
        backgroundColor: theme.colors.accent,
      }
    : {
        color: theme.colors.text,
        backgroundColor: theme.colors.background,
      };
  return (
    <Box
      as="button"
      sx={{
        ...baseStyles,
        ...hoverStyles,
        '&:hover': hoverStyles,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

function Intersection({ onVisible }) {
  const target = React.useRef(null);
  const onVisibleRef = React.useRef(onVisible);

  React.useEffect(() => {
    onVisibleRef.current = onVisible;
  });

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const isIntersecting = entries.some(e => e.isIntersecting);
      if (isIntersecting) {
        onVisibleRef.current();
      }
    });
    observer.observe(target.current);
    return () => observer.disconnect();
  }, []);
  return <div ref={target} />;
}

function Search(props) {
  const { theme } = useThemeUI();
  // this will be the same every time and because this re-renders on every
  // keystroke I'm pretty sure useMemo is appropriate here. (thanks Kent!)
  // console.log('posts', props.posts);
  const blogposts = React.useMemo(() => {
    return props.posts
      .filter(
        t =>
          !t.frontmatter.draft &&
          t.internal.type &&
          t.internal.type === 'Garden',
      )
      .sort((a, b) => {
        return Date.parse(a.frontmatter.date) < Date.parse(b.frontmatter.date)
          ? 1
          : -1;
      })
      .map(e => ({
        id: e.id,
        ...e.frontmatter,
        excerpt: e.parent.excerpt,
      }));
  }, [props.posts]);

  const categories = React.useMemo(
    () => Array.from(new Set(blogposts.flatMap(post => [...post.categories]))),
    [blogposts],
  );

  const statuses = React.useMemo(
    () => Array.from(new Set(blogposts.flatMap(post => post.garden))),
    [blogposts],
  );

  const [search, setSearch] = useQueryParamState('q');
  const [filteredBlogPosts, setFilteredBlogPosts] = React.useState(
    // if there's a search, let's wait for it to load
    // otherwise let's initialize to the blogposts (wow!)
    search || props.search ? [] : blogposts,
  );

  const [maxPostsToRender, setMaxPostsToRender] = React.useState(
    props.max || 10,
  );
  React.useEffect(() => {
    if (props.max) setMaxPostsToRender(props.max);
  }, [props.max]);
  const blogPostsToDisplay = filteredBlogPosts.slice(0, maxPostsToRender);
  React.useEffect(() => {
    // Check to see if we are only searching from props.search, then not from entry
    if (!search && !props.search) {
      setFilteredBlogPosts(blogposts);
    }
    const searchString = props.search || search;
    // Get the web worker and use the search
    getMatchSorterWorker()
      .searchAndSort(blogposts, searchString, {
        keys: [
          {
            key: 'title',
            threshold: matchSorterRankings.CONTAINS,
          },
          {
            key: 'categories',
            threshold: matchSorterRankings.CONTAINS,
            maxRanking: matchSorterRankings.CONTAINS,
          },
          {
            key: 'keywords',
            threshold: matchSorterRankings.CONTAINS,
            maxRanking: matchSorterRankings.CONTAINS,
          },
          {
            key: 'description',
            threshold: matchSorterRankings.CONTAINS,
            maxRanking: matchSorterRankings.CONTAINS,
          },
          {
            key: 'garden',
            threshold: matchSorterRankings.CONTAINS,
            maxRanking: matchSorterRankings.CONTAINS,
          },
        ],
      })
      .then(
        results => setFilteredBlogPosts(results),
        error => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
  }, [blogposts, search, props.search]);

  function handleCategoryClick(category) {
    setSearch(s => {
      if (s.includes(category)) {
        return s
          .split(category)
          .join('')
          .trim();
      }
      return `${s.trim()} ${category}`.trim();
    });
  }

  function handleStatusClick(status) {
    // there should only be one status at a time in search
    function clean(arr, exclude) {
      let newArr = [];
      arr.forEach(word => {
        if (word !== exclude && !statuses.includes(word) && word.length)
          newArr.push(word);
      });
      return newArr;
    }
    setSearch(s => {
      const sArray = clean(s.trim().split(' '), status);
      if (wordExists(status, s)) {
        return clean(sArray).join(' ');
      }
      sArray.push(status);
      return sArray.join(' ');
    });
  }

  return (
    <Box>
      {!props.search ? (
        <Box sx={{ maxWidth: 500, margin: 'auto' }}>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Box
              as="input"
              sx={{
                width: '100%',
                paddingRight: 50,
                color: `${theme.colors.text}`,
                backgroundColor: `${theme.colors.secondary}30`,
                outlineOffset: '-1px',
                outlineColor: `${theme.colors.accent}60`,
                boxShadow: `${theme.colors.primary} 0px 0px 3px`,
                borderRadius: 4,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: `${theme.colors.primary}80`,
                borderImage: 'initial',
                padding: '5px 10px',
                touchAction: 'manipulation',
                lineHeight: 'inherit',
                // appearance: 'textfield',
                // '&[type="search"]': { appearance: 'none' },
              }}
              onChange={event => setSearch(event.target.value)}
              type="search"
              placeholder="Search Posts"
              aria-label="Search Posts"
              value={search}
              autoFocus
            />
            <Box
              sx={{
                margin: 0,
                minWidth: 0,
                position: 'absolute',
                right: 14,
                top: '0.2em',
                opacity: 0.6,
                fontSize: '0.8rem',
                lineHeight: 'inherit',
              }}
            >
              {filteredBlogPosts.length}
            </Box>
          </Box>
          <Box>
            {categories.map(category => (
              <CategoryButton
                key={category}
                onClick={() => handleCategoryClick(category)}
                isSelected={search.includes(category)}
              >
                {category}
              </CategoryButton>
            ))}
          </Box>
          <Box>
            {statuses.map(status => (
              <CategoryButton
                key={status}
                onClick={() => handleStatusClick(status)}
                isSelected={wordExists(status, search)}
              >
                {status}
              </CategoryButton>
            ))}
          </Box>
          {!filteredBlogPosts.length ? (
            <Box as="small" sx={{ marginTop: 10, display: 'block' }}>
              {`Having trouble finding something? Try `}
              <OutsideLink
                sx={{ fontSize: 'inherit', fontWeight: 700 }}
                href="https://www.google.com/search?q=site%3Atony.alves.dev%2Fgarden+javascript"
              >
                using Google
              </OutsideLink>
              {'.'}
            </Box>
          ) : null}
        </Box>
      ) : null}
      {props.isCards ? (
        <BlogPostCards blogposts={blogPostsToDisplay} />
      ) : (
        <BlogPostList blogposts={blogPostsToDisplay} />
      )}
      {!props.max && maxPostsToRender < filteredBlogPosts.length ? (
        <>
          <Box sx={{ marginTop: 20, textAlign: 'center' }}>
            Oh? You wanna scroll do you? Rendering all the posts...
          </Box>
          {props.max ? null : (
            <Intersection onVisible={() => setMaxPostsToRender(Infinity)} />
          )}
        </>
      ) : null}
      {filteredBlogPosts.length === 0 ? (
        <Box as="p" sx={{ ml: '20px' }}>
          Nothing posted at this time.
        </Box>
      ) : null}
    </Box>
  );
}

export default Search;

/*
eslint
  no-func-assign: "off",
  jsx-a11y/no-autofocus: "off"
*/
