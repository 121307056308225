import React from 'react';
import { Link } from 'gatsby';
// import { GatsbyLinkButton } from '@talves/gatsby-theme-components-tui';
import { Box, useThemeUI, useColorMode } from '../../provider';

function setupColors(state, action) {
  const textColor =
    (action.theme && action.theme.colors && action.theme.colors.text) ||
    'black';
  const bgColor =
    (action.theme && action.theme.colors && action.theme.colors.background) ||
    'white';
  const borderColor =
    (action.theme && action.theme.colors && action.theme.colors.muted) ||
    'white';
  switch (action.colorMode) {
    case 'dark':
      return {
        textColor,
        bgColor,
        borderColor,
      };
    default:
      return {
        textColor,
        bgColor,
        borderColor,
      };
  }
}

const PostsListItem = ({ data }) => {
  const { theme } = useThemeUI();
  const [colorMode] = useColorMode();
  const [state, dispatch] = React.useReducer(setupColors, {});

  React.useEffect(() => {
    dispatch({ theme, colorMode });
  }, [theme, colorMode]);

  return (
    <Box
      sx={{
        position: 'relative',
        // margin: '1rem',
        color: state.textColor,
      }}
    >
      <Box
        as={Link}
        to={data.path}
        sx={{
          display: 'block',
          textDecoration: 'none',
          borderStyle: 'none',
          '&:hover': {
            outline: 0,
          },
        }}
        variant="styles.Link"
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 3,
            padding: '1em 1em 1em',
            borderBottom: `1px solid ${theme.colors.primary}`,
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
            // ml: '2px',
            overflow: 'hidden',
            '&:hover': {},
            '&:hover > #swipe': {
              left: 0,
              transform: `skew()`,
              borderBottom: `2px solid ${theme.colors.primary}`,
            },
          }}
        >
          <Box
            id="swipe"
            sx={{
              content: "''",
              position: 'absolute',
              zIndex: -1,
              top: 0,
              left: '-100%',
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.15)',
              transition: '0.2s ease-in-out 0s',
              pointerEvents: 'none',
            }}
          />
          {data.garden ? (
            <Box
              sx={{
                float: 'right',
                borderRadius: '50%',
                background: `url(/images/garden/${data.garden}.svg), ${theme.colors.secondary}`,
                backgroundSize: 60,
                backgroundRepeat: 'no-repeat',
                height: 60,
                width: 60,
                mr: 10,
              }}
            />
          ) : null}
          <Box
            as="h2"
            sx={{
              mb: '0.5em',
              color: 'accent',
            }}
            variant="styles.h2"
          >
            {data.title}
          </Box>
          <Box
            as="p"
            variant="styles.p"
            sx={{
              fontSize: '18px',
              fontWeight: 500,
              pl: '1em',
            }}
          >
            {data.description}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PostsListItem;
