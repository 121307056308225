import React from 'react';
import { Button, GatsbyLink } from '@talves/gatsby-theme-components-tui';
import { Box, useThemeUI } from '../../../provider';

export default function BlogPostCard({ blogpost }) {
  const {
    path: slug,
    productionUrl,
    title,
    description,
    keywords,
    image,
  } = blogpost;
  const defaultCopyText = 'Copy URL';
  const [copyText, setCopyText] = React.useState(defaultCopyText);
  const { theme } = useThemeUI();

  React.useEffect(() => {
    let current = true;
    if (copyText !== defaultCopyText) {
      setTimeout(() => {
        if (current) {
          setCopyText(defaultCopyText);
        }
      }, 3000);
    }
    return () => (current = false);
  }, [copyText]);

  function copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(productionUrl).then(
      () => {
        setCopyText('Copied');
      },
      () => {
        setCopyText('Error copying text');
      },
    );
  }

  return (
    <Box
      sx={{
        margin: 20,
        width: 320,
        background: theme.colors.background,
        boxShadow: `0 1px 3px 0 ${theme.colors.primary}`,
        borderRadius: 5,
        padding: 30,
        position: 'relative',
        paddingBottom: 60,
      }}
    >
      <GatsbyLink to={slug} sx={{ color: 'initial' }}>
        <Box
          as="h2"
          sx={{
            color: theme.colors.text,
            marginTop: 0,
          }}
        >
          {title}
        </Box>
        <Box sx={{ width: '100%' }}>
          <Button
            // as="button"
            sx={{
              display: 'block',
              width: '100%',
              position: 'absolute',
              bottom: '0',
              left: '0',
              borderTopRightRadius: '0',
              borderTopLeftRadius: '0',
            }}
            onClick={copy}
          >
            {copyText}
          </Button>
        </Box>
        <Box
          as="img"
          sx={{ maxHeight: 100, width: 'auto', padding: 'auto' }}
          src={image}
          alt={keywords.join(', ')}
        />
        <Box sx={{ color: theme.colors.text, margin: '16px 0 0 0' }}>
          {description}
        </Box>
      </GatsbyLink>
    </Box>
  );
}
